import React, { useMemo } from 'react'
import { Col, Row } from 'react-grid-system'

import { EditUserModalProps } from './types'
import { Button, Modal } from '../../common'
import { DateInput, Dropdown, Input, Toggle } from '../../common/form/fields'
import { userRoleOptions } from './constants'

import * as styles from './users.module.scss'
import { parseDate } from '../../../common/utils/functions'

const EditUserModal = ({
   isOpen,
   handleClose,
   title,
   userDetails,
   onSubmit,
   validationErrors,
   handleDropdownChange,
   handleToggleChange,
   handleDateChange,
}: EditUserModalProps) => {
   const roleOptions = useMemo(
      () =>
         userDetails.Role === 'User'
            ? userRoleOptions
            : userRoleOptions.filter((opt) => opt.value !== 'User'),
      [isOpen]
   )
   const defaultRole = useMemo(
      () => userRoleOptions.filter((opt) => opt.value === userDetails.Role),
      [userDetails?.Role]
   )
   return (
      <Modal isModalVisible={isOpen} closeModal={handleClose} title={title}>
         <form noValidate onSubmit={onSubmit} className={styles.editModalForm}>
            <input name="ID" id="ID" type="hidden" value={userDetails.ID} />
            <Dropdown
               defaultValue={defaultRole}
               name="Role"
               onChange={(options: any) =>
                  handleDropdownChange('Role', options)
               }
               options={roleOptions}
               id="Role"
               label="Role"
               placeholder="Role"
               required
            />
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="FirstName"
                     error={validationErrors?.FirstName}
                     label="First Name"
                     value={userDetails.FirstName}
                     required
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="LastName"
                     error={validationErrors?.LastName}
                     label="Last Name"
                     value={userDetails.LastName}
                     required
                  />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="Email"
                     error={validationErrors?.Email}
                     label="Email Address"
                     value={userDetails.Email}
                     required
                     inputProps={{
                        type: 'email',
                     }}
                  />
               </Col>
               <Col xs={6}>
                  <Input id="Phone" label="Phone" value={userDetails.Phone} />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="Company"
                     label="Company"
                     value={userDetails.Company}
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="Industry"
                     label="Industry"
                     value={userDetails.Industry}
                     noMargin
                  />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <div className={styles.toggle} style={{ marginBottom: 0 }}>
                     <span className={styles.toggleField}>
                        <Toggle
                           id="DisplayCompanyName"
                           size="md"
                           isChecked={userDetails.DisplayCompanyName}
                           onToggle={handleToggleChange}
                        />
                     </span>
                     <span className={styles.toggleLabel}>
                        Display Company Name
                     </span>
                  </div>
               </Col>
            </Row>

            <hr />

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="Address1"
                     label="Address Line 1"
                     value={userDetails.Address1}
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="Address2"
                     label="Address Line 2"
                     value={userDetails.Address2}
                  />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="Address3"
                     label="Address Line 3"
                     value={userDetails.Address3}
                     noMargin
                  />
               </Col>
               <Col xs={6}>
                  <Input id="City" label="City" value={userDetails.City} />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="State"
                     label="State/Province"
                     value={userDetails.State}
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="ZipCode"
                     label="Postal Code"
                     value={userDetails.ZipCode}
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="Country"
                     label="Country"
                     value={userDetails.Country}
                     noMargin
                  />
               </Col>
            </Row>

            <hr />

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <div className={styles.toggle}>
                     <span className={styles.toggleField}>
                        <Toggle
                           id="EnterpriseUser"
                           size="md"
                           isChecked={userDetails.EnterpriseUser}
                           onToggle={handleToggleChange}
                        />
                     </span>
                     <span className={styles.toggleLabel}>Enterprise User</span>
                  </div>
               </Col>
               <Col xs={6}>
                  <div className={styles.toggle}>
                     <span className={styles.toggleField}>
                        <Toggle
                           id="EmailConfirmed"
                           size="md"
                           isChecked={userDetails.EmailConfirmed}
                           onToggle={handleToggleChange}
                        />
                     </span>
                     <span className={styles.toggleLabel}>Verified User</span>
                  </div>
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="EnterpriseUsersAllowed"
                     label="Enterprise Users Allowed"
                     value={userDetails.EnterpriseUsersAllowed}
                     inputProps={{
                        type: 'number',
                        min: 0,
                        max: 100,
                     }}
                  />
               </Col>
               <Col xs={6}>
                  <DateInput
                     id="EntepriseExpiryDate"
                     label="Enterprise Expiry Date"
                     minDate={new Date()}
                     onChange={(date: Date) =>
                        handleDateChange('EntepriseExpiryDate', date)
                     }
                     selectedDate={parseDate(userDetails.EntepriseExpiryDate)}
                     inputProps={{
                        type: 'number',
                     }}
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="SetPassword"
                     label="Set Password"
                     value={userDetails.SetPassword}
                     noMargin
                  />
               </Col>
            </Row>

            <hr />

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Button minWidth="100%" type="submit">
                     Save
                  </Button>
               </Col>
               <Col xs={6}>
                  <Button
                     type="button"
                     minWidth="100%"
                     variant="tertiary"
                     onClick={handleClose}
                     isReset
                  >
                     Cancel
                  </Button>
               </Col>
            </Row>
         </form>
      </Modal>
   )
}

export default EditUserModal
